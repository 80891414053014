import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className='App'>
      <img src="roopaboodhun.PNG" height='40%'/>
    </div>
  );
}

export default App;
